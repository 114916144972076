<template>
  <div class="c">
    <p>一、活动时间：</p>
    <p>2022年11月7日上午10点——2022年11月14日<span style="color: rgb(51, 51, 51);">上午10点</span>
    </p>
    <p>二、活动内容：</p>
    <p>1.活动期间在<span style="color: rgb(227, 55, 55);">该活动页面</span>购买永久会员的用户，
      即可享受返现金活动，最高可全额返还，会员中心页等其他页面不可<span
          style="color: rgb(51, 51, 51);">享受返现金活动</span>。</p>
    <p>
	<strong style="color: rgb(227, 55, 55);">注：此活动不可和其他优惠同时参与</strong>
</p>
<p>2.购买完用户可到活动页面参与抽奖，抽中并领取奖品后系统会实时将返现现金发放至有鱼钱包当中，
  可自行查看并提取，<font>用户购买后超过活动时间未领取/抽奖</font>，视为用户主动放弃该奖品。</p>
<p>
	<b style="color: rgb(227, 55, 55);">注：打款可能会有一定延迟，若有延迟请耐心等待。</b>
</p>
<p>三、付费服务</p>
  <p>1.你理解并同意，会员订阅服务为付费服务，有鱼记账将收取互联网增值服务使用费；你可以在相关服务页面查阅会员订阅服务具体期限及对应费用并自行选择服务的种类。开启会员订阅功能后，
    无论你是否在相应服务期限内实际使用该服务，已支付的费用不支持退款。</p>
  <p>2.你理解并同意，我司有权自行决定并不时修订会员订阅服务相关的收费标准和规则，该类标准及规则一经发布即生效，
    并构成本条款的有效组成部分。如你不同意前述标准、规则及其修订，你有权停止使用会员订阅服务。你继续使用会员订阅服务即视为你同意相关内容。</p>
  <p>四、免责声明</p>
  <p>我司有权根据产品计划和运营情况独立决定会员订阅服务的具体内容，并有权根据情况新增、移除暂停或终止提供会员订阅服务的部分服务。前述决定不经通知即生效，你同意我司对此免责。</p>
  <p>
    <b>本活动最终解释权归有鱼记账所有，如有疑问可添加企业微信，联系有鱼客服进行咨询。</b>
  </p>
<p>
	<b>微信扫描下方二维码，即可添加客服
		<br>
		<img alt="Image" src="https://download-cos.yofish.com/youyu-gongjushiyebu/20221021144635433-kefu2.png" width="200" height="200">
	</b>
</p>
</div>
</template>
<script>
export default {
  name: 'DaysRule',
};
</script>
<style lang="scss" scoped>
  p {
    font-size: 26px;
    line-height: 42px;
  }
</style>
