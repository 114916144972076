<template>
  <div class="marguee-row">
    <div class="marquee_box">
      <ul class="marquee_list" :class="{marquee_top: animate}">
        <li v-for="(item, index) in marqueeList" :key="index">
          <span>{{item.name}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Marguee',
  data() {
    return {
      animate: false,
    };
  },
  props: {
    marqueeList: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    window.setInterval(this.showMarquee, 2000);
  },
  methods: {
    showMarquee() {
      this.animate = true;
      window.setTimeout(() => {
        this.marqueeList.push(this.marqueeList[0]);
        this.marqueeList.shift();
        this.animate = false;
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
.marquee_box {
  display: block;
  position: relative;
  width: 589px;
  height: 30px;
  overflow: hidden;
  margin: auto;
  top: 10px;
}
.marquee_list {
  display: block;
  position: absolute;
  width: 100%;
}
.marquee_top {
	transition: all 0.5s;
	margin-top: -30px
}

.marquee_list li {
	height: 30px;
	line-height: 30px;
	font-size: 26px;
	// padding-left: 20px;
  // font-weight: 600;
  color: #ffffff;
  font-family: OPPOSans;
  text-align: center;
}

.marquee_list li span {
	padding: 0 2px;
}
</style>
