<template>
  <div class="nut-actionsheet">
     <transition :name="isAnimation ? 'nutFade' : ''" v-if="isShowMask">
      <div class="nut-actionsheet-mask" @click="clickActionSheetMask" v-show="isVisible"></div>
    </transition>
     <transition :name="isAnimation ? 'nutSlideUp' : ''">
      <div class="nut-actionsheet-panel" v-show="isVisible">
        <div class="nut-actionsheet-title" v-if="$slots.sTitle">
          <slot name="sTitle"></slot>
          <div class="close-btn" @click="closeActionSheet" v-if="isCloseBtn">
            <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAY
            AAABXAvmHAAAAAXNSR0IArs4c6QAAApBJREFUaEPtmFmKFEEQhr85gOAVfNEDe
            AEvIe77vo77vqMoiuI4o46KoigqegQf9ACewCv4oCC+S0CWBEl3Z0RlJM1A1Vt
            3Rf7xf5FZuc2wxJ+ZJe6fAWDaPTj0wNADlRWwDKE1Kce3ylye5muBL8DvUqMSw
            GrgexK5AVwtCQa8/wQIgDwlf8UAqf5XZeoWcCnA5DgJbf4nsAL4OylfkRC4Dlx
            RIneA8w0gtHmRXwfIfxMfC4AI3AQuKqV7wJmSuON9bn4j8MHS3gogWreBc0r0A
            XDSkqQQk5vfCry16noARPMucFqJPwJmrclGxOXmdwCvPXpeANG+D5xQSR4Dhz1
            JU2xufjfw0qvTB0ByzAFHVbJF4IAjeW5+H/Dc0f5/aF8AEVgADqmkL4C9BhO5+
            YPAU0O7kSE1ACIoifcr5VfArglmcvNHUiH6+i8uZBZh6fo9KvANsN3wwR5LQ9G
            SY2xMbQ90wvLx7VRZ3gFb1O+88qfSZFBlXhpHAYiWTH/blKOPwIa0mnZ7G3l9N
            k3H1eajAURPFqDNytlntTGTvy+kBTHEfAsA0ZQtwPoRDi+nLUmY+VYAopuP+Wt
            pUxhqvhVAbr4z3QQi8iMeV3kx3gwiEiCvfDfm5RTXDCIKIDefzzbNICIAcvPj5
            vkmELUA3hU2HKIGIDd/HHhomCdDIfoC5OblbDBvMN+FhEH0AcjNy5ngicN8KIQ
            XIOwklSiqe8IDEHKGHdFTskboGz/Xim0FqL49KAyx3hAWgKp7G8e30QuiBCCH9
            GfKxCbgvcOUNzSHWAX8mCRSAlgO/ErX3HL1Ubyr9DoufBMlf+Yj5TLgT4A5q8T
            KUuU7oSKhNeO04gaAaVV+GELTrvzQA0MPBFXgH/DLeTHq2VVZAAAAAElFTkSuQmCC" alt="">
          </div>
        </div>
        <div class="nut-actionsheet-custom" slot-scope>
          <slot name="custom"></slot>
        </div>
        <dl class="nut-actionsheet-modal" v-if="$slots.title || $slots.subTitle">
          <dt class="nut-actionsheet-title"><slot name="title" slot-scope></slot></dt>
          <dd class="nut-actionsheet-sub-title"><slot name="sub-title" slot-scope></slot></dd>
        </dl>
        <ul class="nut-actionsheet-menu">
          <li
            class="nut-actionsheet-item"
            :class="{ 'nut-actionsheet-item-active': isHighlight(item),
            'nut-actionsheet-item-disabled': item.disable }"
            v-for="(item, index) of menuItems"
            :key="index"
            @click="chooseItem(item, index)"
            >{{ item[optionTag] }}</li
          >
        </ul>
        <div class="nut-actionsheet-cancel" v-if="cancelTxt"
        @click="cancelActionSheet">{{ cancelTxt }}</div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'YActionsheet',
  props: {
    isAnimation: {
      type: Boolean,
      default: true,
    },
    isLockBgScroll: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    isCloseBtn: {
      type: Boolean,
      default: true,
    },
    isShowMask: {
      type: Boolean,
      default: true,
    },
    isClickChooseClose: {
      type: Boolean,
      default: true,
    },
    isClickCloseMask: {
      type: Boolean,
      default: true,
    },
    cancelTxt: {
      type: String,
      default: '',
    },
    optionTag: {
      type: String,
      default: 'name',
    },
    chooseTagValue: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    isVisible(value) {
      !!value && this.$emit('open');
      if (this.isLockBgScroll) {
        if (value) {
          document.body.classList.add('nut-overflow-hidden');
        } else {
          this.removeLockScroll();
        }
      }
    },
  },
  deactivated() {
    this.removeLockScroll();
  },
  destroyed() {
    this.removeLockScroll();
  },
  methods: {
    removeLockScroll() {
      document.body.classList.remove('nut-overflow-hidden');
    },
    isHighlight(item) {
      return (this.chooseTagValue && this.chooseTagValue === item[this.optionTag])
      || this.chooseTagValue === 0;
    },

    closeActionSheet() {
      this.$emit('close');
    },

    cancelActionSheet() {
      this.closeActionSheet();
      this.$emit('cancel');
    },

    clickActionSheetMask() {
      this.isClickCloseMask && this.closeActionSheet();
    },

    chooseItem(item, index) {
      if (!item.disable) {
        if (this.isClickChooseClose) {
          this.closeActionSheet();
        }
        this.$emit('choose', item, index);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/fade.scss';
@import '../../styles/slide-up.scss';
.nut-actionsheet-panel {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  max-height: 80%;
  overflow: auto;
  z-index: 10001;
  background-color: rgb(246, 246, 246);
  -webkit-overflow-scrolling:touch;
}

.nut-actionsheet-item {
  border-bottom: 1px solid rgb(246, 246, 246);
  height: 24px;
  padding: 10px;
  line-height: 24px;
  font-size: 14px;
  color: rgb(45, 45, 45);
  text-align: center;
  background-color: rgb(255, 255, 255);
}
.nut-actionsheet-mask {
  position: fixed;
  z-index: 9998;
  // inset: 0px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.nutSlideUp-enter-active {
  animation-duration: .8s;
  animation-fill-mode: both;
  animation-timing-function: nutSlideUpIn;
}
.nutFade-enter-active,
.nutFadeIn {
  animation-name: nutFadeIn;
}
.nutFade-leave-active,
.nutFadeOut {
  animation-name: nutSlideUpOut;
}
.nut-actionsheet-title {
  position: relative;
  overflow: hidden;
  .close-btn {
    width: 50px;
    position: absolute;
    top: 15px;
    right: 28px;
    img {
      width: 100%;
    }
  }
}
</style>
