<template>
  <div class="home-page" :style="style">
    <div class="loading" v-show="loading">{{ textcontent }}</div>
    <div class="page-row">
      <div class="head img-common">
        <div class="marguee-row" v-if="marqueeList.length">
          <y-marguee :marqueeList="marqueeList" />
        </div>
      </div>
      <div class="prize-module img-common" ref="prizeBox">
        <div class="lottery">
          <div class="lottery-item">
            <ul>
              <li v-for="(item, i) in prizeList"
                :class=" i === index ? 'on' : ''"
                :key="i">
                <div class="box">
                  <img :src="item.imageUrl" alt="" />
                </div>
                <div class="prize-desc">{{item.name}}</div>
              </li>
            </ul>
          </div>
          <div class="pay-btn animate"
            v-if="!isLottering && !openCount && !isPay">
            <img
              @click="payAction"
              src="//download-cos.yofish.com/yofish-gongjushiyebu/2022102716272128-pay-btn.png"
              alt="">
          </div>
          <div class="pay-btn" v-if="!isLottering && openCount && !isPay">
            <img
              @click="startLottery"
              src="//download-cos.yofish.com/yofish-gongjushiyebu/20221027162837416-lottery-btn.png"
              alt="">
          </div>
          <div class="receive-btn" v-if="!isLottering && isPay"
            :class="{ disabled: isDisabled }"
            @click="receivePrizeAction">
            <img
              v-if="!isDisabled"
              src="//download-cos.yofish.com/yofish-gongjushiyebu/20221027162956217-receive-btn.png"
              alt="">
              <img
              v-if="isDisabled"
    src="//download-cos.yofish.com/yofish-gongjushiyebu/202210271632021-receive-disabled-btn.png"
              alt="">
          </div>
          <div class="receive-btn" v-if="isLottering">
            <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20221027162914664-lottering-btn.png" alt="">
          </div>
        </div>
      </div>
      <div class="vip-module img-common">
        <div class="more-text" @click="jumpUrl">查看更多>></div>
      </div>
      <div class="rule-btn" @click="ruleVisible = true">
        <span>活动规则</span>
      </div>
    </div>
    <y-actionsheet :is-visible="ruleVisible"
      :isLockBgScroll="true"
      @close="closeActionsheet">
      <span slot="sTitle">活动规则</span>
      <div slot="custom">
        <rule />
      </div>
    </y-actionsheet>
    <div class="loading-animate">
      <y-loading v-show="loadingVisible" />
    </div>
    <!----活动时间弹窗--->
    <y-dialog :visible.sync="dialogData.visible" :appendToBody="false"
      class="activity-dialog-main"
      :is-out-click="dialogData.type === 'wechart'">
      <div class="activity-dialog">
        <h1 class="title">{{ dialogData.title }}</h1>
        <p class="content">{{ dialogData.content }}</p>
      </div>
      <div class="dialog-btns" slot="footer">
        <div class="btn primary" @click="okAction">我知道了</div>
      </div>
    </y-dialog>

    <!-----支付成功弹窗------>
    <y-dialog  :visible.sync="paySuccessVisible" :appendToBody="false"
      class="pay-success-dialog">
      <div class="close-image" slot="header" @click="paySuccessVisible = false">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221024140036315-close-btn.png" alt="">
      </div>
      <div class="button" @click="startLottery('modal')">
        <img
          src="//download-cos.yofish.com/yofish-gongjushiyebu/20221024141849277-lottery-btn.png"
          alt="">
      </div>
    </y-dialog>

    <!-----中奖弹窗------->
    <y-dialog  :visible.sync="winPrizeData.visible" :appendToBody="false"
      class="win-prize-dialog">
      <div class="close-image" slot="header" @click="winPrizeData.visible = false">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221024140036315-close-btn.png" alt="">
      </div>
      <div class="prize">
        <img :src="winPrizeData.prizeUrl" alt="">
        <!-- <img src="https://download-cos.yofish.com/ad-gongjushiyebu/20221021165722762-edj.png" alt=""> -->
      </div>
      <div class="button" @click="receivePrizeAction">
        <img
          src="//download-cos.yofish.com/yofish-gongjushiyebu/20221024143737702-receive-btn.png"
          alt="">
      </div>
    </y-dialog>
  </div>
</template>
<script>
import {
  getWinnerRotationDataApi,
  getBlindboxActivityInfoApi,
  getUserInfoStatusApi,
  getMyBlindboxPrizeApi,
  getBlindboxOpenTimeApi,
  exchangePrizeBlindboxApi,
  openMyBlindboxApi,
} from '@/api/base1';
import { getAppStatus } from '@/utils/app';
import YMarguee from '@/components/marguee';
import YActionsheet from '@/components/actionsheet';
import rule from './rule';
import YLoading from '@/components/loading';
import YDialog from '@/components/dialog';
import { debounce, isArray, changeParam } from '@/utils';

export default {
  name: 'Home',
  components: {
    YMarguee,
    YActionsheet,
    rule,
    YLoading,
    YDialog,
  },
  data() {
    return {
      index: -1, // 当前转动到哪个位置，起点位置
      times: 0, // 转动次数
      prizeIndex: 1, // 中奖位置
      speed: 100, // 初始转动速度
      count: 3, // 总共有多少个位置
      endTime: 2000, // 停止时长
      timer: null,
      marqueeList: [],
      prizeList: [],
      ruleVisible: false,
      loadingVisible: false,
      loading: false,
      textcontent: '',
      style: {},
      dialogData: {
        title: '当前活动未开始！',
        content: '',
        visible: false,
        type: 'activityTime',
      },
      winPrizeData: {
        visible: false,
        prizeUrl: '',
        prizeId: '',
        submiting: false,
      },
      paySuccessVisible: false,
      openCount: 0, // 是否付款
      isDisabled: false, // 是否已领取
      isPay: false, // 是否付款
      isLottering: false,
    };
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return '598e4673-e8a3-416a-b833-4ca22b0ea170';
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
      // return '7fafe1a4-ec10-4a41-b33e-365845d41032';
    },
    activityId() { // 双11活动ID
      return 4;
    },
    businessType() { // 活动标识
      return 0;
    },
    disabledScroll() {
      return this.ruleVisible || this.dialogData.visible
      || this.paySuccessVisible || this.winPrizeData.visible;
    },
  },
  watch: {
    paySuccessVisible(val) {
      const orderId = sessionStorage.getItem('orderId11');
      if (!val && orderId) sessionStorage.removeItem('orderId11');
    },
  },
  created() {
    this.winnerRotationData();
    this.cuserId && this.blindMonitor();
  },
  mounted() {
    document.title = '会员抽免单';
    this.loadAction();
    this.$nextTick((_) => {
      this.pageScroll();
    });
  },
  methods: {
    loadAction() {
      this.lottering = false;
      this.getPrizeList();
      this.getUserInfo();
      this.getOpenTimes();
    },
    async getUserInfo() {
      let res;
      const { cuserId } = this;
      try {
        res = await getUserInfoStatusApi({ cuserId });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1) {
        this.isLifelongVip = res?.results || 0;
      }
    },
    async winnerRotationData() {
      let res;
      const { activityId } = this;
      try {
        res = await getWinnerRotationDataApi({ activityId });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1) {
        let { results } = res;
        results = results.map((item) => ({ ...item, name: `尾号${item.suffixNum}刚刚抽中了${item.prizeName}!` }));
        this.marqueeList = results;
      }
    },
    async getPrizeList() {
      let res;
      this.loadingVisible = true;
      const { cuserId, activityId } = this;
      try {
        res = await getBlindboxActivityInfoApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        this.loadingVisible = false;
        return this.$toast(e?.desc || '网络异常');
      }
      this.loadingVisible = false;
      if (res?.code === 1) {
        let { prizeList } = res?.results?.prizePool;
        this.prizeList = prizeList;
        this.getMyPrizeList();
        const { startTime, endTime } = res?.results || {};
        this.pageStatus(startTime, endTime);
      }
    },
    async getOpenTimes() {
      let res;
      const { cuserId, activityId } = this;
      const orderId = sessionStorage.getItem('orderId11');
      try {
        res = await getBlindboxOpenTimeApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1) {
        this.openCount = res?.results || 0;
        if (orderId && this.openCount) {
          this.paySuccessVisible = true;
          window?._monitor('pageset', 'hycmdhdy_zfcgtc_imp', {
            eventName: '会员抽免单活动页-支付成功弹窗曝光',
          });
        }
      }
    },
    async getMyPrizeList() {
      let res;
      let prizeList = [];
      const { cuserId, activityId } = this;
      try {
        res = await getMyBlindboxPrizeApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        return this.$toast({ content: e?.desc || '网络异常' });
      }
      if (res?.code === 1) {
        prizeList = isArray(res?.results) ? res.results : [];
      } else {
        return this.$toast({ content: res?.desc || '网络异常' });
      }
      this.isDisabled = !prizeList.filter((item) => item.exchangeStatus === 0).length;
      this.isPay = !!prizeList.length;
      if (prizeList.length) this.winPrizeData.prizeId = prizeList[0].id;
      if (prizeList.length) {
        let { prizeId } = prizeList[0];
        let index = this.prizeList.findIndex((item) => item.id === prizeId);
        console.log(index);
        this.index = index;
      }
    },
    payAction() {
      if (this.isLifelongVip) return this.$toast({ content: '您已是永久会员，无需购买！' });
      let { origin } = window.location;
      let payMoney = origin.includes('jz-h5.yofish.com') ? 298 : 0.01;
      let params = {
        payMoney,
        payType: 0, // 0 支付宝 1：零钱
        smsCode: '', // 验证码 payType=1必传
        jumpUrl: origin + '/activity/single/days', // 跳转链接 payType=0必传
        scenes: 6, // 支付场景 0：皮肤 1：购买会员 2:导出数据 3：数据恢复 4：打赏 6: 拆盲盒
        goodsId: `blindbox_activity_${this.activityId}`, // 购买的商品id,(皮肤，会员必传)
        orderId: '', // 订单id ：scenes=2和3必传(需要先调用对应的生成订单接口)
        memo: '', // 备注 ：scenes=4必传
        activityId: this.activityId,
      };
      this.$router.push({
        path: '/payment',
        query: params,
      });
      window?._monitor('track', 'hycmdhdy_qktan_click', {
        eventName: '会员抽免单活动页-去开通按钮点击',
      });
    },
    startLottery(submitType) {
      if (submitType === 'modal') this.$refs.prizeBox.scrollIntoView(false);
      this.paySuccessVisible = false;
      if (this.lottering) return false;
      this.lottering = true;
      this.isLottering = true;
      this.disBlindBoxAction();
      window?._monitor('track', 'hycmdhdy_ljcjan_click', {
        eventName: '会员抽免单活动页-立即抽奖按钮点击',
      });
    },
    startRoll() {
      this.times += 1;
      let {
        times,
        speed,
        endTime,
        prizeIndex,
        index,
      } = this;
      this.oneRoll();
      // console.log(speed * times);
      if (speed * times > 6000) {
        this.isLottering = false;
        return this.$toast({ content: '未找到中奖信息, 请稍后重试' });
      }
      if (speed * times > endTime && prizeIndex === index) {
        this.times = 0;
        this.index = index;
        this.timer && window.clearTimeout(this.timer);
        this.winPrizeData.visible = true;
        this.lottering = false;
        this.isLottering = false;
        this.getOpenTimes();
        window?._monitor('pageset', 'hycmdhdy_zjtc_imp', {
          eventName: '会员抽免单活动页-中奖弹窗曝光',
        });
        return false;
      }
      this.timer = window.setTimeout(this.startRoll, this.speed);
    },
    oneRoll() {
      let { index, count } = this;
      index += 1;
      if (index > count - 1) index = 0;
      this.index = index;
    },
    closeActionsheet() {
      this.ruleVisible = false;
    },
    jumpUrl() {
      this.$router.push('/activity/single/vip');
    },
    pageScroll() {
      let startPosition = 0;
      const page = document.querySelector('.home-page');
      let distance = 0;
      page.addEventListener('touchstart', (e) => {
        const top = 'scrollTop' in page ? page.scrollTop : 0;
        if (this.disabledScroll || top > 0) return false;
        this.textcontent = '下拉刷新';
        startPosition = e.touches[0].pageY;
      });
      page.addEventListener('touchmove', (e) => {
        const top = 'scrollTop' in page ? page.scrollTop : 0;
        if (this.disabledScroll) return false;
        let currentPosition = e.touches[0].pageY;
        distance = currentPosition - startPosition;
        if (distance <= 0 || top > 0) return false;
        if (distance > 80) {
          this.textcontent = '释放立即刷新';
          this.loading = true;
        }
        if (distance > 80 && distance < 150) {
          this.loading = true;
          this.style = {
            transition: 'transform .6s',
            transform: 'translateY(.5rem)',
          };
        }
      });
      page.addEventListener('touchend', (e) => {
        const top = 'scrollTop' in page ? page.scrollTop : 0;
        console.log(top);
        if (this.disabledScroll || top > 0) return false;
        if (distance > 0 && distance < 80) {
          this.style = {};
          this.loading = false;
          return false;
        }
        if (distance > 80) {
          this.style.transform = 'translateY(1rem)';
          this.textcontent = '正在刷新...';
          this.loading = true;
          this.loadAction();
          setTimeout(() => {
            this.style = {};
            this.loading = false;
            distance = 0;
          }, 400);
        }
      });
    },
    pageStatus(startTime, endTime) {
      let currentTime = new Date().getTime();
      if (currentTime < startTime) {
        this.dialogData = {
          title: '当前活动未开始！',
          content: '',
          visible: true,
          type: 'activityTime',
        };
        return false;
      }
      if (currentTime > endTime) {
        this.dialogData = {
          title: '当前活动已结束！',
          content: '',
          visible: true,
          type: 'endTime',
        };
        return false;
      }
    },
    okAction() {
      this.dialogData.visible = false;
      const { type } = this.dialogData;
      window?.android?.jzClose();
      window?.ios?.jzClose();
    },
    async receivePrizeAction() {
      this.winPrizeData.visible = false;
      if (this.isDisabled) return this.$toast({ content: '已领取奖品，请前往“钱包”查看' });
      if (this.winPrizeData.submiting) return false;
      this.winPrizeData.submiting = true;
      const { cuserId, activityId } = this;
      const { prizeId } = this.winPrizeData;
      let data = { cuserId, winningPrizeId: prizeId, activityId };
      data = changeParam(data);
      let appInfo;
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        appInfo = window.ios.jzAppInfo();
      } else if (/android/i.test(navigator.userAgent)) {
        appInfo = window.android.jzAppInfo();
        this.type = 2;
      } else {
        appInfo = {
          name: 'com.jz.youyu',
          channel: '官网',
          version: '5.1.0',
          osType: 1,
        };
        appInfo = JSON.stringify(appInfo);
      }
      appInfo = JSON.parse(appInfo);
      const header = {
        name: encodeURI(appInfo.name),
        osType: appInfo.osType,
        channel: encodeURI(appInfo.channel),
        releaseVersion: appInfo.version,
      };
      let res;
      try {
        res = await exchangePrizeBlindboxApi({}, data, header);
      } catch (e) {
        console.log(e);
        this.winPrizeData.submiting = false;
        return this.$toast({ content: e?.desc || '网络异常' });
      }
      this.winPrizeData.submiting = false;
      if (res?.code === 1) {
        this.$toast({ content: '领取成功，请前往“钱包”查看' });
        this.isDisabled = true;
        this.loadAction();
      }
    },
    blindMonitor() {
      window._monitor('init', {
        businessType: this.businessType,
        businessId: this.activityId,
        cuserId: this.cuserId,
      });
      window._monitor('pageset', 'hycmdhdym_imp', {
        eventName: '会员抽免单活动页面曝光',
      });
    },
    disBlindBoxAction: debounce(async function (data) {
      let res;
      console.log('this', this.getPrizeData);
      const { cuserId, activityId } = this;
      if (this.disabled) return false;
      this.disabled = true;
      try {
        res = await openMyBlindboxApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        this.disabled = false;
        this.isLottering = false;
        return this.$toast({
          content: '奖品抽取失败，请稍后再试',
        });
      }
      if (res?.code === 1) {
        this.winPrizeData.prizeId = res?.results || '';
        this.getMyPrizeList();
        this.getPrizeData(res?.results || '');
      }
    }, 600, true),
    getPrizeData(id) {
      const { prizeList } = this;
      let prizeData = prizeList.find((item) => item.id === id);
      if (prizeData) {
        this.winPrizeData.prizeUrl = prizeData.imageUrl;
        let index = this.prizeList.findIndex((item) => item.id === id);
        this.prizeIndex = index;
      }
      this.startRoll();
      // this.disabled = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
@import './styles/index.scss';
</style>
