<template>
  <transition name="dialog-fade" @after-leave="onAfterLeave">
    <div class="y-dialog__wrapper" v-show="visible">
      <div class="y-dialog-main">
        <div class="y-dialog">
          <div class="y-dialog-header">
            <slot name="header"></slot>
          </div>
          <div class="y-dialog__body">
            <slot></slot>
          </div>
          <div class="y-dialog__footer"
            v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
      <!-- <div class="v-model" v-show="visible"></div> -->
    </div>
  </transition>
</template>
<script>
export default {
  name: 'YDialog',
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    isOutClick: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(val) {
      console.log('visible', val);
      document.body.style.overflow = val ? 'hidden' : '';
      val && document.addEventListener('touchmove', this.bodyScroll, false);
      !val && document.removeEventListener('touchmove', this.bodyScroll, false);
      if (val) {
        if (this.appendToBody) {
          document.body.appendChild(this.$el);
        }
        if (this.isOutClick) {
          document.addEventListener('click', (e) => {
            e.stopPropagation();
            if (e.target.classList.contains('y-dialog__wrapper')) {
              this.$emit('update:visible', false);
            }
          });
        }
      }
    },
  },
  mounted() {
  },
  methods: {
    onAfterLeave() {},
    bodyScroll(event) {
      event.preventDefault();
    },
  },
};
</script>
<style scoped lang="scss">
  .v-model {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
    z-index: 1;
  }
  .y-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    z-index: 10;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
  }
  // .y-dialog-main {
  //   position: relative;
  //   z-index: 30;
  // }
  .y-dialog-main {
    width: 624px;
    height: 395px;
    background: #FFFFFF;
    border-radius: 20px;
    position: relative;
    z-index: 20;
    // overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
